<script>
	import { NOTES, DAYS } from "./store";
	import { nanoid } from "nanoid";
	import { slide } from "svelte/transition";

	const PUNCTUATION = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
	const KEYWORDS = {
		SEARCH: ["suche", "suchen", "suche nach", "starte suche"],
		NUMBERS: ["teilenummer", "teile nummer", "nummer"],
		STOP: ["stop", "stopp", "ende", "fertig", "beenden"],
	};

	let running = false;
	let interim = [];
	let noteContainer = null;

	function resultOfSpeechRecognition(event) {
		const INPUT = event.results[event.resultIndex];

		if (!INPUT.isFinal) {
			interim = INPUT[0].transcript.split(" ");
		} else {
			interim = [];

			const TRANSCRIPT = INPUT[0].transcript.replace(PUNCTUATION, "");

			if (KEYWORDS.STOP.includes(TRANSCRIPT.toLowerCase())) {
				recognition.stop();
				return;
			}

			const IS_NUMER_SEARCH = KEYWORDS.NUMBERS.some((KEY) =>
				TRANSCRIPT.toLowerCase().includes(KEY.toLowerCase())
			);

			let highestIndex = 0;

			[...KEYWORDS.SEARCH, ...KEYWORDS.NUMBERS].forEach((KEY) => {
				const INDEX = TRANSCRIPT.toLowerCase().indexOf(KEY.toLowerCase());

				if (INDEX !== -1) {
					const CHAR_COUNT = INDEX + KEY.length;
					if (CHAR_COUNT > highestIndex) highestIndex = CHAR_COUNT;
				}
			});

			const message = TRANSCRIPT.slice(highestIndex).trim();

			if (message) {
				noteContainer.scrollTo({ top: noteContainer.scrollHeight });

				NOTES.add({
					id: nanoid(),
					timestamp: new Date().toISOString(),
					number: IS_NUMER_SEARCH,
					transcript: TRANSCRIPT,
					message,
				});
			}
		}
	}

	const SpeechRecognition =
		window.SpeechRecognition || window.webkitSpeechRecognition;
	const recognition = new SpeechRecognition();

	recognition.continuous = true;
	recognition.interimResults = true;
	recognition.lang = "de-DE";
	recognition.addEventListener("start", () => {
		running = true;
	});
	recognition.addEventListener("end", () => {
		running = false;
	});
	recognition.addEventListener("result", resultOfSpeechRecognition);

	function isToday(date) {
		const ISO_TODAY = new Date().toISOString().split("T")[0];
		return date === ISO_TODAY;
	}

	function getRelativeDate(date) {
		const DATE_FORMAT = new Intl.DateTimeFormat("de", { dateStyle: "full" });
		const TIME_FORMAT = new Intl.DateTimeFormat("de", { timeStyle: "short" });

		return {
			DATE: DATE_FORMAT.format(new Date(date)),
			TIME: TIME_FORMAT.format(new Date(date)),
		};
	}

	function editNote(note) {
		const message = window.prompt("Notiz bearbeiten", note.message);
		if (message) NOTES.edit(note.id, { ...note, message });
	}

	function deleteNote(note) {
		if (window.confirm(`"${note.message}" wirklich löschen?`)) {
			NOTES.delete(note.id);
		}
	}
</script>

<div
	class="w-screen h-screen bg-true-gray-100 text-dark-100"
	style="max-height: -webkit-fill-available"
>
	{#if SpeechRecognition}
		<section
			class="flex h-screen flex-col bg-white max-w-screen-md mx-auto"
			style="max-height: -webkit-fill-available"
		>
			<header
				class="border-b-2 border-gray-300 bg-gradient-to-b from-white to-[#f3f4f5] via-white overflow-hidden"
			>
				<div class="text-right px-3 pt-1 text-true-gray-500">
					<a
						href="https://fricke.de/fricke-gruppe/fricke-innovations-lab"
						target="_blank"
						class="inline-flex gap-1 items-center text-xs group filter grayscale hover:grayscale-0 transition"
					>
						<span class="uppercase transition tracking-wider text-primary">
							Gebaut von
						</span>
						<figure class="select-none pointer-events-none pl-[2px]">
							<img src="/logo_frix.png" alt="GranitLogo" width="42" />
						</figure>
					</a>
				</div>
				<div class="flex items-start gap-3 pb-5 px-3 relative">
					<figure class="select-none pointer-events-none px-2">
						<img src="/logo_granit.png" alt="GranitLogo" width="60" />
					</figure>
					<img
						src="/radio-waves.png"
						alt="radio waves"
						width="180"
						class="absolute -bottom-20 right-4 opacity-5 select-none pointer-events-none"
					/>
					<div class="flex-1">
						<h1 class="text-2xl font-semibold">GRANIT Sprachnotizen</h1>
						<p class="text-gray-600">
							Starten Sie die Spracherfassung und notieren Sie per einfacher
							Spracheingabe Suchbegriffe oder Teilenummern, um danach direkt zur
							GRANIT-Suche zu springen
						</p>
					</div>
				</div>
			</header>
			<main
				class="flex-1 flex flex-col-reverse overflow-auto"
				bind:this={noteContainer}
			>
				<div class="py-2">
					{#each $DAYS as DAY (DAY.date)}
						<section transition:slide|local={{ duration: 500 }}>
							<div class="my-4 flex items-center gap-2 select-none">
								<hr class="flex-1" />
								<span class="text-gray-400">
									{isToday(DAY.date) ? "Heute" : getRelativeDate(DAY.date).DATE}
								</span>
								<hr class="flex-1" />
							</div>
							{#each DAY.notes as NOTE (NOTE.id)}
								<div
									transition:slide|local={{ duration: 500 }}
									class="hover:bg-true-gray-50 group relative"
								>
									<div
										class="absolute right-3 top-0 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 shadow bg-white border rounded-md flex"
									>
										<div
											class="py-1 px-2 rounded-l text-true-gray-400 hover:text-true-gray-600 cursor-pointer text-sm"
											on:click={editNote(NOTE)}
										>
											Bearbeiten
										</div>
										<div
											class="py-1 px-2 rounded-r text-true-gray-400 hover:text-red-600 cursor-pointer text-sm"
											on:click={deleteNote(NOTE)}
										>
											Löschen
										</div>
									</div>
									<a
										href="https://www.granit-parts.com/e/search?q={NOTE.message}"
										target="_blank"
										class="p-3 block"
									>
										<div class="uppercase tracking-wider text-sm">
											<span class="text-primary">
												{NOTE.number ? "Teilenummer" : "Suche"}
											</span>
											<span class="text-gray-500">
												notiert um {getRelativeDate(NOTE.timestamp).TIME}:
											</span>
										</div>
										<p class="text-xl">
											"<strong class="text-dark-800 group-hover:underline"
												>{NOTE.message}</strong
											>"
										</p>
									</a>
								</div>
							{/each}
						</section>
					{/each}
				</div>
			</main>
			<footer>
				<div class="p-3 border-t-1 flex justify-center">
					<div
						class="bg-primary p-3 rounded-4xl flex items-center gap-2 cursor-pointer"
						on:click={() => {
							running ? recognition.stop() : recognition.start();
						}}
						class:animate-pulse={running}
						class:w-full={running}
					>
						<img
							src="/microphone-white.png"
							alt="Mikrofon"
							width="32"
							class="select-none"
						/>
						{#if running}
							<div class="flex-1 overflow-hidden">
								<div class="flex flex-row-reverse flex-nowrap px-3 relative">
									<div
										class="absolute left-0 h-full w-3 bg-gradient-to-r from-primary to-transparent"
									/>
									{#each interim.reverse() as WORD}
										<strong class="mx-[3px] text-xl text-primary-100">
											{WORD + " "}
										</strong>
									{/each}
								</div>
							</div>
						{/if}
					</div>
				</div>
			</footer>
		</section>
	{:else}
		<section class="max-w-screen-sm pt-25vh mx-auto space-y-6">
			<h1 class="text-2xl font-bold">
				Ihr Browser wird leider nicht unterstützt
			</h1>
			<p class="leading-loose">
				Um diesen Fehler zu beheben müssen sie entweder ihren Browser auf die
				neueste Version aktualisieren oder einen anderen Browser installieren,
				welcher unterstützt wird.
			</p>
			<p class="leading-loose">
				<a
					href="https://www.google.de/chrome/"
					target="_blank"
					class="font-bold text-blue-500 hover:underline"
					rel="noopener noreferrer"
				>
					Chrome
				</a>,
				<a
					href="https://www.mozilla.org/de/firefox/new/"
					target="_blank"
					class="font-bold text-blue-500 hover:underline"
					rel="noopener noreferrer"
				>
					Firefox
				</a>
				und
				<a
					href="https://www.microsoft.com/de-de/edge"
					target="_blank"
					class="font-bold text-blue-500 hover:underline"
					rel="noopener noreferrer"
				>
					Edge
				</a>
				werden empfohlen.
			</p>
		</section>
	{/if}
</div>

<style windi:preflights:global windi:safelist:global>
:global(*), :global(::before), :global(::after) {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
:global(*) {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
}
:global(:root) {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}
:global(:-moz-focusring) {
  outline: 1px dotted ButtonText;
}
:global(:-moz-ui-invalid) {
  box-shadow: none;
}
:global(::moz-focus-inner) {
  border-style: none;
  padding: 0;
}
:global(::-webkit-inner-spin-button), :global(::-webkit-outer-spin-button) {
  height: auto;
}
:global(::-webkit-search-decoration) {
  -webkit-appearance: none;
}
:global(::-webkit-file-upload-button) {
  -webkit-appearance: button;
  font: inherit;
}
:global([type='search']) {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
:global(abbr[title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
:global(body) {
  margin: 0;
  font-family: inherit;
  line-height: inherit;
}
:global(html) {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  line-height: 1.5;
}
:global(a) {
  color: inherit;
  text-decoration: inherit;
}
:global(b), :global(strong) {
  font-weight: bolder;
}
:global(button), :global(input), :global(optgroup), :global(select), :global(textarea) {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 0;
  line-height: inherit;
  color: inherit;
}
:global(button), :global(select) {
  text-transform: none;
}
:global(button), :global([type='button']), :global([type='reset']), :global([type='submit']) {
  -webkit-appearance: button;
}
:global(blockquote), :global(dl), :global(dd), :global(h1), :global(h2), :global(h3), :global(h4), :global(h5), :global(h6), :global(hr), :global(figure), :global(p), :global(pre) {
  margin: 0;
}
:global(button) {
  background-color: transparent;
  background-image: none;
}
:global(button:focus) {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
:global(button), :global([role="button"]) {
  cursor: pointer;
}
:global(code), :global(kbd), :global(samp), :global(pre) {
  font-size: 1em;
}
:global(fieldset) {
  margin: 0;
  padding: 0;
}
:global(hr) {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}
:global(h1), :global(h2), :global(h3), :global(h4), :global(h5), :global(h6) {
  font-size: inherit;
  font-weight: inherit;
}
:global(img) {
  border-style: solid;
}
:global(input::placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(input::webkit-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(input::-moz-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(input:-ms-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(input::-ms-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(img), :global(svg), :global(video), :global(canvas), :global(audio), :global(iframe), :global(embed), :global(object) {
  display: block;
  vertical-align: middle;
}
:global(img), :global(video) {
  max-width: 100%;
  height: auto;
}
:global(legend) {
  padding: 0;
}
:global(ol), :global(ul) {
  list-style: none;
  margin: 0;
  padding: 0;
}
:global(progress) {
  vertical-align: baseline;
}
:global(pre), :global(code), :global(kbd), :global(samp) {
  font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}
:global(small) {
  font-size: 80%;
}
:global(sub), :global(sup) {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
:global(sub) {
  bottom: -0.25em;
}
:global(sup) {
  top: -0.5em;
}
:global(summary) {
  display: list-item;
}
:global(table) {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}
:global(textarea) {
  resize: vertical;
}
:global(textarea::placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(textarea::webkit-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(textarea::-moz-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(textarea:-ms-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
:global(textarea::-ms-input-placeholder) {
  opacity: 1;
  color: #9ca3af;
}
.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.bg-true-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.hover\:bg-true-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 0, 11, var(--tw-bg-opacity));
}
.bg-gradient-to-b {
  background-image: -o-linear-gradient(top, var(--tw-gradient-stops));
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--tw-gradient-stops)));
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}
.bg-gradient-to-r {
  background-image: -o-linear-gradient(left, var(--tw-gradient-stops));
  background-image: -webkit-gradient(linear, left top, right top, from(var(--tw-gradient-stops)));
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.from-white {
  --tw-gradient-from: rgba(255, 255, 255, var(--tw-from-opacity, 1));
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.from-primary {
  --tw-gradient-from: rgba(227, 0, 11, var(--tw-from-opacity, 1));
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.via-white {
  --tw-gradient-stops: var(--tw-gradient-from), rgba(255, 255, 255, var(--tw-via-opacity, 1)), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.to-\[\#f3f4f5\] {
  --tw-gradient-to: rgba(243, 244, 245, var(--tw-to-opacity, 1));
}
.to-transparent {
  --tw-gradient-to: transparent;
}
.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-4xl {
  border-radius: 2rem;
}
.rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.border {
  border-width: 1px;
}
.border-b-2 {
  border-bottom-width: 2px;
}
.border-t-1 {
  border-top-width: 1px;
}
.cursor-pointer {
  cursor: pointer;
}
.block {
  display: block;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
.flex-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.flex-col-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.flex-nowrap {
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.items-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.h-screen {
  height: 100vh;
}
.h-full {
  height: 100%;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.leading-loose {
  line-height: 2;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mx-\[3px\] {
  margin-left: 3px;
  margin-right: 3px;
}
.max-w-screen-md {
  max-width: 768px;
}
.max-w-screen-sm {
  max-width: 640px;
}
.opacity-5 {
  opacity: 0.05;
}
.opacity-0 {
  opacity: 0;
}
.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.p-3 {
  padding: 0.75rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pl-\[2px\] {
  padding-left: 2px;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.pt-25vh {
  padding-top: 25vh;
}
.pointer-events-none {
  pointer-events: none;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.-bottom-20 {
  bottom: -5rem;
}
.right-4 {
  right: 1rem;
}
.right-3 {
  right: 0.75rem;
}
.top-0 {
  top: 0px;
}
.left-0 {
  left: 0px;
}
.shadow {
  --tw-shadow-color: 0, 0, 0;
  --tw-shadow: 0 1px 3px 0 rgba(var(--tw-shadow-color), 0.1), 0 1px 2px 0 rgba(var(--tw-shadow-color), 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.text-right {
  text-align: right;
}
.text-dark-100 {
  --tw-text-opacity: 1;
  color: rgba(60, 60, 60, var(--tw-text-opacity));
}
.text-true-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(115, 115, 115, var(--tw-text-opacity));
}
.text-primary {
  --tw-text-opacity: 1;
  color: rgba(227, 0, 11, var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.text-true-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(163, 163, 163, var(--tw-text-opacity));
}
.hover\:text-true-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgba(82, 82, 82, var(--tw-text-opacity));
}
.hover\:text-red-600:hover {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-dark-800 {
  --tw-text-opacity: 1;
  color: rgba(24, 24, 24, var(--tw-text-opacity));
}
.text-primary-100 {
  --tw-text-opacity: 1;
  color: rgba(255, 217, 219, var(--tw-text-opacity));
}
.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.uppercase {
  text-transform: uppercase;
}
.group:hover .group-hover\:underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.tracking-wider {
  letter-spacing: 0.05em;
}
.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.w-screen {
  width: 100vw;
}
.w-3 {
  width: 0.75rem;
}
.w-full {
  width: 100%;
}
.gap-1 {
  grid-gap: 0.25rem;
  gap: 0.25rem;
}
.gap-3 {
  grid-gap: 0.75rem;
  gap: 0.75rem;
}
.gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.transform {
  --tw-rotate: 0;
  --tw-rotate-x: 0;
  --tw-rotate-y: 0;
  --tw-rotate-z: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-translate-z: 0;
  -webkit-transform: rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z));
  -ms-transform: rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z));
  transform: rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z));
}
.-translate-y-1\/2 {
  --tw-translate-y: -50%;
}
.transition {
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
@-webkit-keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
.animate-pulse {
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.grayscale {
  --tw-grayscale: grayscale(100%);
}
.hover\:grayscale-0:hover {
  --tw-grayscale: grayscale(0);
}
</style>
