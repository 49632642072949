import { writable, derived } from 'svelte/store';

function initializeStore() {
  const notes = JSON.parse(localStorage.getItem('notes'));
  const { subscribe, set, update } = writable(notes || []);

	return {
		subscribe,
    set: (notesArray) => {
      localStorage.setItem('notes', JSON.stringify(notesArray));
      set(notesArray);
    },
		add: (note) => {
      update((currentNotes) => {
        currentNotes.push(note)
        localStorage.setItem('notes', JSON.stringify(currentNotes))
        return currentNotes
      })
    },
    edit: (id, note) => {
      update((currentNotes) => {
        const noteIndex = currentNotes.findIndex((note) => note.id === id)
        currentNotes[noteIndex] = note
        localStorage.setItem('notes', JSON.stringify(currentNotes))
        return currentNotes
      })
    },
    delete: (id) => {
      update((currentNotes) => {
        const noteIndex = currentNotes.findIndex((note) => note.id === id)
        currentNotes.splice(noteIndex, 1)
        localStorage.setItem('notes', JSON.stringify(currentNotes))
        return currentNotes
      })
    },
		wipe: () => {
      localStorage.removeItem('notes')
      set([])
    },
	};
}

export const NOTES = initializeStore();

export const DAYS = derived(NOTES, ($NOTES) => {
  const DAYS = $NOTES.reduce((days, note) => {
    const date = note.timestamp.split("T")[0];
    if (!days[date]) days[date] = [];

    days[date].push(note);
    return days;
  }, {})

  return Object.keys(DAYS).map((date) => {
    return {
      date,
      notes: DAYS[date]
    };
  });
})